/* colour palette https://coolors.co/28166b-5b2c83-9d4685-ca819b-f7bbb0 */

* {
  box-sizing: border-box;
}

*:not(canvas) {
  z-index: 1;
}

body {
  overflow-x: hidden;
  color: white;
  font-family: "Open Sans", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  background: rgb(40, 22, 107);
  background: linear-gradient(
    125deg,
    rgb(40, 22, 107) 0%,
    rgb(91, 44, 131) 15%,
    rgb(247, 187, 176) 100%
  );
  background-attachment: fixed;
  /* prevent switching between portfolioView shifting contents */
  overflow-y: scroll;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
li,
button {
  font-family: "Work Sans", sans-serif;
  font-weight: 300;
  margin: 0;
}

/* large screens */
@media screen and (min-width: 1281px) {
  h1 {
    font-size: 10rem;
  }
}

/* small screens */
@media screen and (max-width: 1280px) {
  h1 {
    font-size: 8rem;
  }
}

h1 {
  font-weight: 100;
}

h2 {
  font-size: 2rem;
  font-weight: 100;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.15rem;
}

sub {
  font-size: 0.8rem;
  vertical-align: baseline;
}

p {
  font-size: 1.25rem;
  line-height: 1.15;
  font-weight: 300;
}

a {
  font-size: 1.25rem;
}

a:visited {
  color: var(--theme-color);
}

li {
  font-size: 1.15rem;
  font-weight: 300;
}

button {
  font-size: 1.25rem;
}
