.contact {
  display: flex;
  gap: 2rem;
}

.banner {
  display: grid;
  background-color: var(--panel-color);
  padding: 32px 18vw;
}

.panel {
  display: grid;
  background-color: var(--panel-color);
  margin: 0 18vw;
  padding: 32px;
}

header {
  height: 100vh;
  width: 100vw;
  margin: 0 10vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

header .contact svg {
  height: 2.5rem;
  width: 2.5rem;
  stroke-width: 0.75;
  stroke: white;
  transition: 150ms ease-in-out;
}

header a:hover svg {
  transform: translateY(-8px);
}

main {
  display: flex;
  flex-direction: column;
  gap: 150px;
}

#info p {
  line-height: 1.15;
  font-weight: 100;
}

footer {
  gap: 2rem;
  margin-top: 150px;
}

footer.panel {
  padding: 64px 18vw 100px;
}

footer .contact {
  display: flex;
  justify-content: flex-start;
}

footer a {
  color: white;
  font-size: 2rem;
  font-weight: 400;
}

#footerImgDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* large screens */
@media screen and (min-width: 1281px) {
  /* bump it over to make it look nice with the h2 */
  header h1 {
    margin-left: -36px;
  }

  header .contact {
    margin: 2em 0;
  }

  #info p {
    font-size: 2rem;
  }

  footer {
    grid-auto-flow: column;
  }

  footer .contact {
    flex-direction: row;
  }

  footer #footerContactDiv {
    padding-bottom: 5rem;
  }

  footer #footerImg {
    height: 100px;
    transform: scale(4) translateY(-32px);
  }
}

/* small screens */
@media screen and (max-width: 1280px) {
  /* bump it over to make it look nice with the h2 */
  header h1 {
    margin-left: -9px;
  }

  header > div {
    display: grid;
    padding-top: 0.25rem;
    gap: 1rem;
  }

  #info p {
    font-size: 1.75rem;
  }

  footer {
    grid-auto-flow: row;
  }

  footer .contact {
    flex-direction: column;
    gap: 1rem;
    padding-left: 2rem;
  }

  footer #footerContactDiv {
    display: grid;
    gap: 1rem;
  }

  footer #footerImg {
    height: 400px;
  }
}
