.codepen {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 200px;
  width: 300px;
  overflow: hidden;
  background-color: #8f83a0;
}

.codepen svg {
  margin: 0;
  padding: 0;
  height: 75%;
  width: 75%;
}
