#exp {
  display: flex;
  flex-direction: column;
  gap: 5rem;
  justify-content: center;
  text-align: center;
}

#exp .panel {
  padding: 32px;
  row-gap: 4rem;
}

#exp #current img {
  height: 150px;
}

.previousJobs {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
}

.previousJobs img {
  height: 100px;
}

.previousJobs p {
  font-size: 1rem;
}

.previousJob {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 250px;
}
